import revive_payload_client_9iexNonHhs from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@20.17.16_db0@0.2.3_eslint@8.57.1_ioredis@_f76670d42d008b5cf3417ff58379f823/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Y4sLdlWY5n from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@20.17.16_db0@0.2.3_eslint@8.57.1_ioredis@_f76670d42d008b5cf3417ff58379f823/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_lkztV5487U from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@20.17.16_db0@0.2.3_eslint@8.57.1_ioredis@_f76670d42d008b5cf3417ff58379f823/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_xXxWkgCHih from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@20.17.16_db0@0.2.3_eslint@8.57.1_ioredis@_f76670d42d008b5cf3417ff58379f823/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ce24bDPNEK from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@20.17.16_db0@0.2.3_eslint@8.57.1_ioredis@_f76670d42d008b5cf3417ff58379f823/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GKhxPG4MGX from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@20.17.16_db0@0.2.3_eslint@8.57.1_ioredis@_f76670d42d008b5cf3417ff58379f823/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_1tUc7qfOYF from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@20.17.16_db0@0.2.3_eslint@8.57.1_ioredis@_f76670d42d008b5cf3417ff58379f823/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/blintz/front-web/hadid/hadid/.nuxt/components.plugin.mjs";
import prefetch_client_WPTVBTQWPc from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@20.17.16_db0@0.2.3_eslint@8.57.1_ioredis@_f76670d42d008b5cf3417ff58379f823/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UyX0REmkZa from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt-gtag@2.1.0_magicast@0.3.5_rollup@4.32.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_9uSIkSCoYC from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt-jsonld@2.1.0_typescript@4.9.5/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import nuxt_enterprise_plugin_xlFHyQu7nL from "/builds/blintz/front-web/hadid/node_modules/.pnpm/@nargarath+vue-recaptcha@3.0.0-alpha.7_magicast@0.3.5_rollup@4.32.1_vue@3.5.13_typescript@4.9.5_/node_modules/@nargarath/vue-recaptcha/dist/nuxt-enterprise-plugin.mjs";
import _00_libs_T2je4nhN4b from "/builds/blintz/front-web/hadid/hadid/plugins/00.libs.ts";
import _02_blockedRoutes_ofiuvz02JG from "/builds/blintz/front-web/hadid/hadid/plugins/02.blockedRoutes.ts";
import _04_sentry_client_tuXFV7LSkY from "/builds/blintz/front-web/hadid/hadid/plugins/04.sentry.client.ts";
import _05_integrationScripts_PGNpEOCB7R from "/builds/blintz/front-web/hadid/hadid/plugins/05.integrationScripts.ts";
export default [
  revive_payload_client_9iexNonHhs,
  unhead_Y4sLdlWY5n,
  router_lkztV5487U,
  payload_client_xXxWkgCHih,
  navigation_repaint_client_ce24bDPNEK,
  check_outdated_build_client_GKhxPG4MGX,
  chunk_reload_client_1tUc7qfOYF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_WPTVBTQWPc,
  plugin_client_UyX0REmkZa,
  plugin_9uSIkSCoYC,
  nuxt_enterprise_plugin_xlFHyQu7nL,
  _00_libs_T2je4nhN4b,
  _02_blockedRoutes_ofiuvz02JG,
  _04_sentry_client_tuXFV7LSkY,
  _05_integrationScripts_PGNpEOCB7R
]