
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91blogPostSlug_93tPdDwRCt9NMeta } from "/builds/blintz/front-web/hadid/hadid/pages/blog/[blogPostSlug].vue?macro=true";
import { default as indexamhJClI1A2Meta } from "/builds/blintz/front-web/hadid/hadid/pages/blog/index.vue?macro=true";
import { default as _91condoSlug_93SrYhyUzOtiMeta } from "/builds/blintz/front-web/hadid/hadid/pages/condominios/[condoSlug].vue?macro=true";
import { default as indexqn2bu2YS2FMeta } from "/builds/blintz/front-web/hadid/hadid/pages/condominios/index.vue?macro=true";
import { default as contatoWKRHsJ46cfMeta } from "/builds/blintz/front-web/hadid/hadid/pages/contato.vue?macro=true";
import { default as _91propertySlug_93ptdffcykPFMeta } from "/builds/blintz/front-web/hadid/hadid/pages/imoveis/[propertySlug].vue?macro=true";
import { default as destaques6eQrwZSgq5Meta } from "/builds/blintz/front-web/hadid/hadid/pages/imoveis/destaques.vue?macro=true";
import { default as index31GOsqqTBsMeta } from "/builds/blintz/front-web/hadid/hadid/pages/imoveis/index.vue?macro=true";
import { default as indexTx62xVSLgxMeta } from "/builds/blintz/front-web/hadid/hadid/pages/index.vue?macro=true";
import { default as mapa_45do_45siterHaEURIUQUMeta } from "/builds/blintz/front-web/hadid/hadid/pages/mapa-do-site.vue?macro=true";
import { default as politica_45de_45privacidadePDl9HFPzbsMeta } from "/builds/blintz/front-web/hadid/hadid/pages/politica-de-privacidade.vue?macro=true";
import { default as quem_45somosYiWu1mi67hMeta } from "/builds/blintz/front-web/hadid/hadid/pages/quem-somos.vue?macro=true";
import { default as selecao_45da_45semanaPGyOjeVcjCMeta } from "/builds/blintz/front-web/hadid/hadid/pages/selecao-da-semana.vue?macro=true";
import { default as servicosu8NGEnQpJ1Meta } from "/builds/blintz/front-web/hadid/hadid/pages/servicos.vue?macro=true";
import { default as tenho_45um_45imovelrCq2A6D30NMeta } from "/builds/blintz/front-web/hadid/hadid/pages/tenho-um-imovel.vue?macro=true";
export default [
  {
    name: "BlogPostPage",
    path: "/blog/:blogPostSlug()",
    meta: _91blogPostSlug_93tPdDwRCt9NMeta || {},
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/blog/[blogPostSlug].vue")
  },
  {
    name: "BlogListing",
    path: "/blog",
    meta: indexamhJClI1A2Meta || {},
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/blog/index.vue")
  },
  {
    name: "CondoPage",
    path: "/condominios/:condoSlug()",
    meta: _91condoSlug_93SrYhyUzOtiMeta || {},
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/condominios/[condoSlug].vue")
  },
  {
    name: "CondosListing",
    path: "/condominios",
    meta: indexqn2bu2YS2FMeta || {},
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/condominios/index.vue")
  },
  {
    name: "ContactPage",
    path: "/contato",
    meta: contatoWKRHsJ46cfMeta || {},
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/contato.vue")
  },
  {
    name: "PropertyPage",
    path: "/imoveis/:propertySlug()",
    meta: _91propertySlug_93ptdffcykPFMeta || {},
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/imoveis/[propertySlug].vue")
  },
  {
    name: "PropertyHighlights",
    path: "/imoveis/destaques",
    meta: destaques6eQrwZSgq5Meta || {},
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/imoveis/destaques.vue")
  },
  {
    name: "PropertiesListing",
    path: "/imoveis",
    meta: index31GOsqqTBsMeta || {},
    alias: ["/imoveis/:transactionTypeSlug?/:propertyTypeSlug?/:countrySlug?/:stateSlug?/:citySlug?/:regionSlug?","/imoveis/aluguel","/imoveis/compra","/imoveis/compra-ou-aluguel"],
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/imoveis/index.vue")
  },
  {
    name: "Home",
    path: "/",
    meta: indexTx62xVSLgxMeta || {},
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/index.vue")
  },
  {
    name: "SiteMap",
    path: "/mapa-do-site",
    meta: mapa_45do_45siterHaEURIUQUMeta || {},
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/mapa-do-site.vue")
  },
  {
    name: "PrivacyPolicy",
    path: "/politica-de-privacidade",
    meta: politica_45de_45privacidadePDl9HFPzbsMeta || {},
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/politica-de-privacidade.vue")
  },
  {
    name: "WhoWeAre",
    path: "/quem-somos",
    meta: quem_45somosYiWu1mi67hMeta || {},
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/quem-somos.vue")
  },
  {
    name: "WeeklySelectionPage",
    path: "/selecao-da-semana",
    meta: selecao_45da_45semanaPGyOjeVcjCMeta || {},
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/selecao-da-semana.vue")
  },
  {
    name: "ServicesPage",
    path: "/servicos",
    meta: servicosu8NGEnQpJ1Meta || {},
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/servicos.vue")
  },
  {
    name: "SendProperty",
    path: "/tenho-um-imovel",
    meta: tenho_45um_45imovelrCq2A6D30NMeta || {},
    component: () => import("/builds/blintz/front-web/hadid/hadid/pages/tenho-um-imovel.vue")
  }
]